import { formatInTimeZone } from 'date-fns-tz';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Info, maintenance as maintenanceIcon } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { useCompany } from '../../../hooks/useCompany';
import { useFetchLocationsQuery } from '../../../services/location.api';
import { useFetchLicenseByLicenseKeyQuery } from '../../../services/license.api';
import { Charger } from '../../../stores/types';
import {
  getISO8601TimezoneOffSetFromTimeZone,
  getLocale,
  formatDate,
  getDifferenceInYearsAndDaysString,
} from '../../../utils/Date.Util';
import {
  ColorType,
  Dropdown,
  FormInput,
  Icon,
  Label,
  LabelType,
  Switch,
} from '../../_ui';
import { InputType } from '../../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import { ChargerStatus } from '../ChargerStatus.component';
import { getRowStatus, handleValidateId, OCPP_ID_LENGTH } from '../utils';
import { useAuth, USER_ROLE } from '../../../hooks';

type ChargerInfoProps = {
  charger: Charger;
  editEnabled: boolean;
  isLoading: boolean;
  handleInputChange: any;
  manufacturerModelData: any;
  manufacturer: any;
  setManufacturer: any;
  setUpdateChargerDetail: any;
  updateChargerDetail: any;
  renderOCPPErrorLabel: any;
  handleOCPPValidation: any;
  validateResponse: any;
};
export const ChargerInfo = ({
  charger,
  editEnabled,
  isLoading,
  handleInputChange,
  manufacturerModelData,
  manufacturer,
  setManufacturer,
  setUpdateChargerDetail,
  updateChargerDetail,
  renderOCPPErrorLabel,
  handleOCPPValidation,
  validateResponse,
}: ChargerInfoProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { companies, userScope } = useCompany();

  const { locations } = useFetchLocationsQuery(
    { scope: userScope!, companyId: charger.ownerId },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint.data ? endPoint.data.entities : [],
      }),
    },
  );

  const { license } = useFetchLicenseByLicenseKeyQuery(
    {
      scope: userScope!,
      companyId: charger.ownerId,
      licenseKey: charger.licenseKey,
    },
    {
      skip: !charger.licenseKey || auth.role !== USER_ROLE.SUPPORT,
      selectFromResult: (endPoint) => ({
        license: endPoint.data ? endPoint.data : null,
      }),
    },
  );

  const locationDropdown = useMemo(() => {
    return locations.map((location) => ({
      id: location.id,
      label: location.name,
      selected: location.id === charger?.location?.id,
    }));
  }, [locations, charger?.location?.id]);

  const manufacturerDropdown = useMemo(() => {
    return manufacturerModelData.reduce((pre: any, curr: any) => {
      const exist = pre.find((obj: any) => {
        return obj.label === curr.vendor;
      });
      if (!exist) {
        pre.push({
          id: curr.vendor,
          label: curr.vendor,
          selected: curr.vendor === charger?.vendor,
        });
      }
      return pre;
    }, []);
  }, [manufacturerModelData, charger?.vendor]);

  const modelDropdown = useMemo(() => {
    return _.get(
      _.mapValues(
        _.groupBy(
          manufacturerModelData.map((model: any) => ({
            id: model.id,
            label: model.model,
            selected: model.model === charger?.model,
            manufacturer: model.vendor,
          })),
          (element) => element.manufacturer,
        ),
        (list) => list.map((obj) => _.omit(obj, 'manufacturer')),
      ),
      manufacturer,
    );
  }, [manufacturerModelData, manufacturer, manufacturerDropdown]);

  useEffect(() => {
    if (!manufacturer) {
      setManufacturer(
        manufacturerDropdown.find((item: any) => item.selected === true)?.label,
      );
    }
  }, [manufacturerDropdown]);

  const companyName = useMemo(() => {
    return companies?.find((c) => c.id === charger?.ownerId)?.name || '';
  }, [charger?.ownerId, companies]);

  const formatRemainingDays = (
    startDate: string | Date,
    endDate: string | Date,
  ) => {
    const { years, remainingDays } = getDifferenceInYearsAndDaysString(
      startDate,
      endDate,
    );
    return t('license_remaining_days', {
      years: years,
      days: remainingDays,
    });
  };

  const renderDisplayId = () => (
    <div className='flex flex-row items-start min-h-[40px]'>
      <Label
        text={t('chargers_display_id')}
        type={LabelType.LABEL_S}
        color={ColorType.GREY6}
        className='min-w-[148px] max-w-[148px] mr-2.5'
      />
      <div className='flex-1'>
        <FormInput
          defaultValue={charger?.name}
          onChange={(event: any) =>
            handleInputChange('displayId', event.target.value)
          }
          onValidation={handleValidateId}
          showErrorIcon
          width='100%'
        />
      </div>
    </div>
  );

  const renderMakeAndModel = () => {
    return editEnabled ? (
      <div className='flex flex-col gap-0'>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('chargers_manufacturer')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!charger?.isPowerManaged && (
            <div className='flex-1'>
              <Dropdown
                headerWidth='100%'
                items={manufacturerDropdown}
                onItemClick={(selectedItem: any) => {
                  setManufacturer(selectedItem.label);
                  handleInputChange('chargerModelId', '');
                }}
                labelType={LabelType.BODY3_G4}
                contentDivWidth={220}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
                headerClassName='bg-grey1 border-grey3 rounded pr-2'
                headerHeight='36px'
              />
            </div>
          )}
          {charger?.isPowerManaged && (
            <Label
              text={manufacturer}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('chargers_model')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!charger?.isPowerManaged && (
            <div className='flex-1'>
              <Dropdown
                headerWidth='100%'
                items={modelDropdown || []}
                disabled={!modelDropdown}
                onItemClick={(selectedItem: any) => {
                  handleInputChange('chargerModelId', selectedItem.id);
                }}
                labelType={LabelType.BODY3_G4}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
                headerClassName='bg-grey1 border-grey3 rounded pr-2'
                headerHeight='36px'
              />
            </div>
          )}
          {charger?.isPowerManaged && (
            <Label
              text={charger.model}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          )}
        </div>
      </div>
    ) : (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('charger_make')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Label
          text={charger ? `${charger?.vendor} / ${charger?.model}` : ''}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderMaintenanceMode = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <div className='flex flex-row gap-[4px] min-w-[148px] max-w-[148px] mr-2.5 items-center'>
          <Label
            text={t('maintenance_flag')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <span
                className='text-sm text-white font-medium'
                style={{ fontFamily: 'Inter' }}
              >
                <Trans
                  i18nKey='maintenance_flag_tip'
                  components={{
                    br: <br />,
                  }}
                />
              </span>
            </TooltipContent>
          </Tooltip>
        </div>
        {editEnabled ? (
          <Switch
            value={updateChargerDetail.maintenanceFlag}
            enableLabel={t('under_maintenance')}
            disableLabel={t('not_under_maintenance')}
            onChange={(value: any) => {
              setUpdateChargerDetail({
                ...updateChargerDetail,
                maintenanceFlag: value,
              });
            }}
            dataTestId='charger-maintenance-switch'
          />
        ) : (
          <Label
            text={t(
              updateChargerDetail.maintenanceFlag
                ? 'under_maintenance'
                : 'not_under_maintenance',
            )}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        )}
      </div>
    );
  };

  const renderCompanyName = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('company')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <div className='flex flex-row gap-[8px]'>
          <Label
            text={companyName}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
        </div>
      </div>
    );
  };

  const renderLocation = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('charger_location')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        {!editEnabled && (
          <Label
            text={charger?.location?.name || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        )}
        {editEnabled && charger?.isPowerManaged && (
          <Label
            text={charger?.location?.name || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        )}
        {editEnabled && !charger?.isPowerManaged && (
          <div className='flex-1'>
            <Dropdown
              placeholder={t('chargers_location')}
              placeholderLabelType={LabelType.DROPDOWN_HEADER}
              headerWidth='100%'
              items={locationDropdown}
              onItemClick={(selectedItem: any) => {
                handleInputChange('locationId', selectedItem.id);
              }}
              labelType={LabelType.BODY3_G4}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
              headerClassName='bg-grey1 border-grey3 rounded pr-2'
              headerHeight='36px'
            />
          </div>
        )}
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('charger_address')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Label
          text={
            charger
              ? `${charger?.location?.streetAddress}, ${charger?.location?.city},
                  ${charger?.location?.stateOrRegion}, ${charger?.location?.isoCountryCode}, ${charger?.location?.zipOrPostalCode}`
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          className='w-52'
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderLastUsed = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('charger_last_used')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5 whitespace-nowrap'
        />
        <Label
          text={
            charger?.lastUsed && charger.location?.timeZone
              ? formatInTimeZone(
                  charger?.lastUsed,
                  charger.location.timeZone,
                  'LLL d, h:mm a',
                  getLocale(),
                ) +
                getISO8601TimezoneOffSetFromTimeZone(charger.location.timeZone)
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderOpccId = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <div className='flex flex-row gap-[4px] min-w-[148px] max-w-[148px] mr-2.5 items-center'>
          <Label
            text={t('chargers_ocpp_id')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <Label
                text={t('charger_ocpp_tip')}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        </div>
        {!editEnabled && (
          <Tooltip>
            <TooltipTrigger>
              <Label
                text={charger?.ocppId || ''}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isLoading}
                skeletonWidth='w-52'
                skeletonHeight='h-5'
                className='truncate max-w-[265px]'
              />
            </TooltipTrigger>
            <TooltipContent style={{ maxWidth: 'fit-content' }}>
              <Label
                text={charger?.ocppId || ''}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        )}
        {editEnabled && (
          <div className='flex-1'>
            <FormInput
              defaultValue={charger?.ocppId}
              onChange={(event: any) => {
                handleOCPPValidation(event.target.value);
                handleInputChange('ocppId', event.target.value);
              }}
              width='100%'
              height='36px'
              maxLen={String(OCPP_ID_LENGTH)}
              inputType={InputType.TEXT}
              validaitonQueryResult={validateResponse.isError}
              showErrorIcon
              errorLabel={renderOCPPErrorLabel()}
              onValidation={handleValidateId}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSerialNumber = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('serial_number_label')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Tooltip>
          <TooltipTrigger>
            <Label
              text={charger?.serialNumber || ''}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLoading}
              skeletonWidth='w-52'
              skeletonHeight='h-5'
              className='truncate max-w-[265px]'
            />
          </TooltipTrigger>
          <TooltipContent style={{ maxWidth: 'fit-content' }}>
            <Label
              text={charger?.serialNumber || ''}
              type={LabelType.BODY3}
              color={ColorType.WHITE}
            />
          </TooltipContent>
        </Tooltip>
      </div>
    );
  };

  const renderLicenseId = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('license_id')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Label
          text={license?.licenseKey || ''}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderLicenseDuration = () => {
    return (
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('license_duration')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Label
          text={
            license
              ? t('license_duration_year_license', {
                  duration: license?.duration,
                })
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderLicenseRemainDays = () => {
    return (
      <div className='flex flex-row gap-2'>
        <div className='flex flex-col gap-0.5'>
          <Label
            text={
              license?.expirationDate
                ? formatRemainingDays(new Date(), license.expirationDate)
                : ''
            }
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </div>
      </div>
    );
  };

  const renderLicenseExpires = () => {
    return (
      <div className='flex flex-row items-start min-h-[60px]'>
        <Label
          text={t('license_expires')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <div className='flex flex-col gap-0.5'>
          <Label
            text={
              license?.expirationDate
                ? formatDate(new Date(license.expirationDate))
                : ''
            }
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
          {renderLicenseRemainDays()}
        </div>
      </div>
    );
  };

  const renderChargerStatus = () => {
    const { timeStamp, status, ports } = getRowStatus(charger);
    return (
      charger?.ports?.length > 1 && (
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('overview_charger_status')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <div className='flex flex-row gap-[8px]'>
            {charger && (
              <ChargerStatus
                status={status}
                ports={ports}
                timeStamp={timeStamp}
                isLoading={isLoading}
              />
            )}
            {updateChargerDetail.maintenanceFlag && (
              <Icon src={maintenanceIcon} size={IconSize.SIZE_20x20} />
            )}
          </div>
        </div>
      )
    );
  };

  return (
    <div className={`flex flex-col ${editEnabled ? 'gap-1' : 'gap-0'} pt-4`}>
      {editEnabled && renderDisplayId()}
      {renderMakeAndModel()}
      {!editEnabled && renderChargerStatus()}
      {renderMaintenanceMode()}
      {renderCompanyName()}
      {renderLocation()}
      {renderAddress()}
      {renderLastUsed()}
      {renderOpccId()}
      {renderSerialNumber()}
      {auth.role === USER_ROLE.SUPPORT && renderLicenseId()}
      {auth.role === USER_ROLE.SUPPORT && renderLicenseDuration()}
      {auth.role === USER_ROLE.SUPPORT && renderLicenseExpires()}
    </div>
  );
};
