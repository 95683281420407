// React
import { useEffect, useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// API
import {
  UpdateChargerInformation,
  useFetchChargerDetailQuery,
  useFetchWifiPasswordQuery,
  useIsValidChargerMutation,
  useUpdateChargerMutation,
  useValidateOCPPMutation,
} from '../../services/charger.api';

// Selectors

// Components
import {
  Button,
  ButtonType,
  Card,
  ColorType,
  getHexColorByType,
  Label,
  LabelType,
  Menu,
  MenuItem,
  Snackbar,
} from '../_ui';

// Assets
import { ButtonSize } from '../_ui/Button.component';

// Utils
import { validateEmail } from '../_ui/modal/utils';
import { handleValidateId } from './utils';

// CSS

// Constants
import { Info, More } from '../../assets/icons';
import { TEXT } from '../../constant/Text.constant';
import { useCompany } from '../../hooks/useCompany';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useLazyGetCircuitsQuery } from '../../services/pm.api';
import { useFetchModelsQuery } from '../../services/provision';
import { i18n } from '../../services/translation/i18n';
import { SCOPE_TYPE } from '../../services/utils';
import { Charger } from '../../stores/types';
import {
  Connectivity,
  OCCPValidationRequest,
} from '../../stores/types/chargers.interface';
import { InternalErrorCode } from '../../stores/types/error.interface';
import { Form } from '../_ui/Form.component';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { AdditionalInfo } from './charger-detail/AdditionalInfo.component';
import { ChargerAutoStart } from './charger-detail/ChargerAutoStart.component';
import { ChargerDeployment } from './charger-detail/ChargerDeployment.component';
import { ChargerInfo } from './charger-detail/ChargerInfo.component';
import { ChargerSetting } from './charger-detail/ChargerSetting.component';
import { ChargerSummery } from './charger-detail/ChargerSummery.component';
import ChargerHistory from './charger-history/ChargerHistory.component';
import ChargerTerminal from './charger-terminal/ChargerTerminal.component';

export const ChargerDetail = () => {
  const { t } = useTranslation();
  const { chargerId: id } = useParams();
  const { navigate } = useNavigateWithSearchParam();
  const { switchCompany, userScope, companyId } = useCompany();

  const { SIMULATED } = TEXT;

  const [updateCharger, updateChargerQueryResult] = useUpdateChargerMutation();
  const [triggerOcppValidate, validateResponse] = useValidateOCPPMutation();
  const { data: WifiPassword } = useFetchWifiPasswordQuery({
    id: id!,
    scope: userScope!,
  });
  const [loadSkeletonAfterEdit, setLoadSkeletonAfterEdit] = useState(false);

  const { allowPayterView } = useAllowedFeatures();

  const [manufacturer, setManufacturer] = useState('');
  const [editEnabled, setEditEnabled] = useState(false);

  const {
    data: chargerData,
    isFetching: isChargerLoading,
    isLoading,
    error,
  } = useFetchChargerDetailQuery(
    {
      id: id!,
      scope: userScope!,
    },
    { pollingInterval: editEnabled ? 0 : 15000 },
  );

  const [triggerGetCircuits, { data: circuitsData }] =
    useLazyGetCircuitsQuery();

  const [triggerValidAutoStart, autoStartQueryResult] =
    useIsValidChargerMutation();

  const filteredCircuits = circuitsData?.entities.filter(
    (circuit) => circuit.locationId === chargerData?.location?.id,
  );

  useEffect(() => {
    const errorObj = error as { originalStatus: number };
    if (errorObj && errorObj.originalStatus === 404) {
      navigate(RoutePath.NOT_FOUND, { replace: true });
    }
  }, [error]);

  const charger = useMemo(() => {
    return chargerData || ({} as Charger);
  }, [chargerData]);

  const chargerCompanyId = useMemo(() => {
    return charger?.ownerId;
  }, [charger]);

  useEffect(() => {
    if (charger?.ownerId) {
      triggerGetCircuits({
        scope: SCOPE_TYPE.COMPANY,
        companyId: charger.ownerId,
      });
    }
  }, [charger?.ownerId]);

  const { manufacturerModelData } = useFetchModelsQuery(undefined, {
    selectFromResult: (endpoint) => ({
      manufacturerModelData: endpoint.data ? endpoint.data : [],
    }),
  });

  const [updateChargerDetail, setUpdateChargerDetail] = useState(
    {} as UpdateChargerInformation,
  );

  useEffect(() => {
    if (charger) {
      setUpdateChargerDetail({
        ...updateChargerDetail,
        id: id || '',
        displayId: charger?.name || '',
        chargerModelId: charger.chargerModelId,
        locationId: charger?.location?.id || '',
        directions: charger?.directions,
        parkingSpot: charger?.parkingSpot,
        associatedBuildingUnit: charger?.associatedBuildingUnit,
        internalNote: charger?.internalNote,
        autoStartConfig: charger?.autoStartConfig,
        hiddenOnMap: charger?.hiddenOnMap,
        priceModelId: charger?.priceModelId,
        maintenanceFlag: charger?.maintenanceFlag,
        ocppId: charger?.ocppId,
        deploymentInfo: {
          deployedOverride: charger.deploymentInfo?.deployed,
          deployedDateOverride: charger.deploymentInfo?.deployedDate,
          installationDate: charger.deploymentInfo?.installationDate,
          installationPerson: charger.deploymentInfo?.installationPerson,
          installationEmail: charger.deploymentInfo?.installerContactEmail,
          connectivityType: charger?.deploymentInfo?.connectivityType
            ? charger?.deploymentInfo?.connectivityType
            : Connectivity.SIM_CARD,
          whitelabeledSimCard: charger.deploymentInfo?.whitelabeledSimCard,
          simCardType: charger.deploymentInfo?.simCardType,
          simCardProvider: charger.deploymentInfo?.simCardProvider,
          simCardId: charger.deploymentInfo?.simCardId,
          wifiName: charger.deploymentInfo?.wifiName,
          wifiPassword: WifiPassword?.wifiPassword,
        },
      });
    }
    if (!isChargerLoading) {
      setLoadSkeletonAfterEdit(false);
    }
  }, [charger, id, editEnabled, WifiPassword, isChargerLoading]);

  const handleInputChange = (key: string, value: any) => {
    setUpdateChargerDetail({ ...updateChargerDetail, [key]: value });
  };

  const handleOCPPValidation = (val: string) => {
    if (val) {
      const req: OCCPValidationRequest = {
        id: charger.id,
        chargerModelId: charger.chargerModelId,
        ocppId: val,
        priceModelId: charger.priceModelId,
        displayId: charger?.name,
        locationId: charger?.location?.id,
      };
      triggerOcppValidate({ request: req, scope: userScope! });
    }
  };
  const renderOCPPErrorLabel = () => {
    if (validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0003) {
        return t('invalid_ocpp_id');
      }
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0034) {
        return t('duplicate_ocpp_id');
      }
    }
  };

  const displayToast = () => {
    Snackbar.show({
      message: (
        <>
          <Label
            text={i18n.t('successfully_configured_auto_start_warning', {
              driverEmail: updateChargerDetail?.autoStartConfig?.email,
            })}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BLACK}
          />
          <Label
            text={i18n.t('valid_payment_warning')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BLACK}
            className='pt-[24px]'
          />
        </>
      ),
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: Info,
      height: 'h-auto',
      iconColor: getHexColorByType(ColorType.WARNING),
      component: 'auto-start',
    });
  };

  useEffect(() => {
    if (autoStartQueryResult.isError && !autoStartQueryResult.isLoading) {
      displayToast();
    }
  }, [autoStartQueryResult]);

  const renderNav = (parent: string, name?: string) => {
    return (
      <div className='flex flex-row gap-1'>
        <Label
          text={parent}
          type={LabelType.BODY2}
          color={ColorType.DENIM}
          onClick={() => switchCompany(companyId!, RoutePath.CHARGERS)}
        />
        <Label
          text={`/ ${name}`}
          type={LabelType.BODY2}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const handleSaveBtnClick = () => {
    updateCharger({
      body: updateChargerDetail,
      params: {
        scope: userScope!,
        companyId: charger.ownerId,
      },
    });
  };

  const handleCancelBtnClick = () => {
    setEditEnabled(false);
    setManufacturer(charger.vendor);
  };

  const onSaveValidation = () => {
    if (
      !updateChargerDetail.displayId ||
      !updateChargerDetail.chargerModelId ||
      !updateChargerDetail.ocppId ||
      !handleValidateId(updateChargerDetail.ocppId) ||
      !updateChargerDetail.deploymentInfo?.connectivityType ||
      !updateChargerDetail.priceModelId ||
      // Need to keep save button disabled when AutoStart Switch is enabled & Driver is selected but no email id is added
      (updateChargerDetail.autoStartConfig?.type === SIMULATED &&
        updateChargerDetail.autoStartConfig?.email !== undefined &&
        !validateEmail(updateChargerDetail.autoStartConfig?.email)) ||
      validateResponse.isError
    ) {
      return false;
    }
    return true;
  };

  const renderBtnDiv = () => {
    if (editEnabled) {
      return (
        <div className='flex flex-row-reverse gap-2'>
          <Button
            label={t('save')}
            type={ButtonType.PRIMARY}
            disabled={!onSaveValidation()}
            size={ButtonSize.SMALL}
            isSumbit
            dataTestId='chargers-save-details'
          />
          <Button
            label={t('cancel')}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            onClick={handleCancelBtnClick}
          />
        </div>
      );
    }
  };

  const verifyAutoStart = () => {
    // verify when users enable autostart wth session === Driver and email is not empty or email changed

    const shouldVerify =
      updateChargerDetail.autoStartConfig?.type === SIMULATED &&
      updateChargerDetail.autoStartConfig?.email &&
      updateChargerDetail.autoStartConfig?.email !==
        charger.autoStartConfig?.email;

    if (shouldVerify) {
      triggerValidAutoStart({
        params: {
          failOnInvalidPaymentMethod: true,
          validateOnly: true,
          scope: userScope!,
          companyId: charger.ownerId,
        },
        body: updateChargerDetail,
        id,
      });
    }
  };

  const renderInfoHeader = () => {
    if (editEnabled) {
      return (
        <div className='flex justify-start'>
          <Label
            text={t('charger_edit_details')}
            type={LabelType.H4}
            color={ColorType.BLACK}
          />
        </div>
      );
    }
    return (
      <>
        <Label
          text={t('charger_details_header')}
          type={LabelType.H4}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonHeight='h-6'
          className='truncate max-w-[364px]'
        />

        <div className='absolute right-0'>
          <Menu
            placement='bottom-end'
            icon={More}
            dataTestId='chargers-details-menu'
          >
            <MenuItem
              label={t('edit_charger')}
              onClick={() => setEditEnabled(!editEnabled)}
              dataTestId='chargers-details-edit-button'
            />
          </Menu>
        </div>
      </>
    );
  };

  const renderChargerSummery = () => {
    return (
      <ChargerSummery
        charger={charger}
        isLoading={
          (isChargerLoading && isLoading) ||
          (isChargerLoading && loadSkeletonAfterEdit)
        }
      />
    );
  };

  const renderChargerDetail = () => (
    <div className='w-[453px]'>
      <Card>
        <Form
          onSubmit={handleSaveBtnClick}
          queryResult={updateChargerQueryResult}
          onQuerySuccess={() => {
            verifyAutoStart();
            setEditEnabled(false);
            setLoadSkeletonAfterEdit(true);
          }}
          onQueryFailed={() => {
            setEditEnabled(false);
          }}
        >
          <div className='flex flex-row justify-between relative'>
            {renderInfoHeader()}
            {renderBtnDiv()}
          </div>
          <div className='divide-y divide-grey2'>
            <ChargerInfo
              charger={charger}
              editEnabled={editEnabled}
              manufacturer={manufacturer}
              setManufacturer={setManufacturer}
              isLoading={isLoading}
              handleInputChange={handleInputChange}
              updateChargerDetail={updateChargerDetail}
              setUpdateChargerDetail={setUpdateChargerDetail}
              manufacturerModelData={manufacturerModelData}
              renderOCPPErrorLabel={renderOCPPErrorLabel}
              handleOCPPValidation={handleOCPPValidation}
              validateResponse={validateResponse}
            />
            <AdditionalInfo
              charger={charger}
              editEnabled={editEnabled}
              isLoading={isLoading}
              handleInputChange={handleInputChange}
            />
            {allowPayterView && (
              <ChargerTerminal
                charger={charger}
                editEnabled={editEnabled}
                handleInputChange={handleInputChange}
              />
            )}
            <ChargerSetting
              charger={charger}
              editEnabled={editEnabled}
              isLoading={isLoading}
              handleInputChange={handleInputChange}
              updateChargerDetail={updateChargerDetail}
              updateChargerQueryResult={updateChargerQueryResult}
              filteredCircuits={filteredCircuits}
            />
            <ChargerAutoStart
              charger={charger}
              editEnabled={editEnabled}
              isLoading={isLoading}
              updateChargerDetail={updateChargerDetail}
              setUpdateChargerDetail={setUpdateChargerDetail}
            />
            <ChargerDeployment
              charger={charger}
              editEnabled={editEnabled}
              isLoading={isLoading}
              handleInputChange={handleInputChange}
              updateChargerDetail={updateChargerDetail}
            />
          </div>
        </Form>
      </Card>
    </div>
  );

  const renderSessions = useMemo(() => {
    if (!charger || !chargerCompanyId) {
      return null;
    }
    return (
      <ChargerHistory
        chargerName={charger.name}
        chargerId={charger.id}
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        chargerTimeZone={chargerData?.location?.timeZone!}
        companyId={chargerCompanyId!}
      />
    );
  }, [charger]);

  return (
    <div className='flex flex-col gap-6'>
      {renderNav(t('chargers'), charger?.name || '')}
      <div className='flex flex-row gap-6'>
        <div className='flex flex-col gap-2'>
          {renderChargerSummery()}
          {renderChargerDetail()}
        </div>
        {renderSessions}
      </div>
    </div>
  );
};
