import { SCOPE_TYPE } from '../../services/utils';
import { Charger } from './sessions.interface';

export interface ChargerList {
  totalCount: number;
  entities: Array<Charger>;
}

export enum Connectivity {
  SIM_CARD = 'SIM_CARD',
  WIFI = 'WIFI',
  ETHERNET = 'ETHERNET',
  VARIES_BY_USER = 'VARIES_BY_USER',
}

export interface RawChargerStatus {
  rawChargerStatusType: string;
  rawChargerStatus: string;
  rawChargerStatusChangedTime: string;
}
export interface ChargerSharedDetails {
  deploymentDate?: any;
  chargerManufacturerId: string;
  chargerModelId: string;
  locationId: string;
  locationName: string;
  priceModelId: string;
  sharedConnectionType: boolean;
  connectivity: string;
  whitelabeledSimCard?: boolean;
  simCardProvider?: string;
  wifiName?: string;
  wifiPassword?: string;
  hiddenOnMap: boolean;
  directions?: string;
  internalNote?: string;
  installer?: string;
  installationDate?: string;
  installerContactEmail?: string;
  deploymentStatus?: boolean;
}

export interface ChargerDetail {
  chargerModelId: string;
  locationId: string;
  priceModelId: string;
  connectivity?: string;
  whitelabeledSimCard?: boolean;
  simCardProvider?: string;
  wifiName?: string;
  wifiPassword?: string;
  hiddenOnMap: boolean;
  directions?: string;
  internalNote?: string;
  installer?: string;
  installationDate?: string;
  installerContactEmail?: string;
  serialNumber?: string;
  ocppId: string;
  displayId: string;
  associatedBuildingUnit?: string;
  parkingSpot?: string;
  simCardId?: string;
}
export interface ChargerSpecificInfo {
  isClaim: boolean;
  chargerSpecificDetail: ChargerDetail;
}
export interface ChargerDetailPayload {
  chargerSharedDetails: ChargerSharedDetails;
  chargerSpecificDetails: ChargerDetail[];
  bulkOperation: boolean;
}

export interface DeploymentInfo {
  deployed?: boolean;
  deployedDate?: string;
  installationDate?: string;
  installationPerson?: string;
  installerContactEmail?: string;
  connectivityType?: string;
  whitelabeledSimCard?: boolean;
  simCardType?: string;
  simCardProvider?: string;
  simCardId?: string;
  wifiName?: string;
  wifiPassword?: string;
  addedBy?: string;
  addedDate?: string;
}

export interface ChargersGroupByLocation {
  [key: string]: Array<Charger>;
}

export interface ChargerSelection {
  id: string;
  label: string;
  selected: boolean;
}

export interface ChargerSelectionList extends ChargerSelection {
  children: Array<ChargerSelection>;
}

export interface FilteredCharger {
  id: string;
  charger: string | undefined;
  location: string | undefined;
  status: string;
  lastUsed: number | undefined;
  pricing: string;
  access: string;
  note: string | undefined;
  bg: string;
}

export interface ClaimCharger {
  ownerType: string;
  companyId?: string;
  locationId?: string;
  serialNumber: string;
  displayId: string;
}

export interface OCCPValidationRequest {
  id: string;
  displayId: any;
  ocppId: string;
  chargerModelId: string;
  locationId: any;
  priceModelId: string;
}

export interface ClaimChargerRequest {
  chargers: ClaimCharger[];
  bulkOperation: boolean;
}

export interface ChargerWifiPassword {
  wifiPassword: string;
}

export interface MoveChargerToast {
  chargerName: string;
  companyName: string;
}
export interface MoveCharger {
  chargerId: string;
  companyId: string;
  locationId: string;
  priceModelId: string;
  hidden: boolean;
  directions?: string;
  note?: string;
  installer?: string;
  installationDate?: string;
  installerContactEmail?: string;
  deployed?: boolean;
  deploymentDate?: string;
}

export interface MoveChargerPayload {
  payload: MoveCharger;
  toast: MoveChargerToast;
}

export type GetChargerDetailApiArg = {
  /** access level */
  scope: SCOPE_TYPE;
  id: string;
};

export enum CHARGER_SUMMARY_STATUS {
  COMING_SOON = 'COMING_SOON',
  AVAILABLE = 'AVAILABLE',
  CHARGING = 'CHARGING',
  OFFLINE = 'OFFLINE',
  OUT_OF_ORDER = 'OUT_OF_ORDER',
}
export type ChargerSummaryResponse = {
  totalCount: number;
  siteHostOverviewStatus: { id: CHARGER_SUMMARY_STATUS; count: number }[];
};

export type ChargerSummaryRequest = {
  'filter_eq[locationId]'?: string | null | undefined;
  companyId?: string;
  'filter_in[companyId]'?: (string | null)[] | null;
  scope?: SCOPE_TYPE;
};
