import { License } from '../stores/types';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE } from './utils';

export const licenseApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchLicenseByLicenseKey: builder.query<
      License,
      { scope?: SCOPE_TYPE; companyId?: string; licenseKey?: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}/${payload.licenseKey}`,
        params: { scope: payload.scope, companyId: payload.companyId },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.LICENSE, id: result?.licenseKey },
      ],
    }),
  }),
});

export const { useFetchLicenseByLicenseKeyQuery } = licenseApi;
